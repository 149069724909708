.fc .fc-view-harness {
  background: white;
}

.fc-toolbar {
  text-transform: capitalize;
}
.fc .fc-col-header-cell-cushion {
  text-transform: capitalize;
}

.fc .fc-button:disabled {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #333;
}

.fc .fc-button-primary {
  background-color: white;
  border-color: #e6e6e6;
  color: #333;
}

.fc .fc-button-primary:not(:disabled):hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #333;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #333;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #333;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #333;
}

.fc .fc-googleCalendarButton-button,
.fc .fc-googleCalendarButton-button {
  background-color: transparent;
  border-color: transparent;
  color: var(--primary);
}

.fc .fc-googleCalendarButton-button:not(:disabled):hover {
  opacity: 0.8;
  color: var(--primary);
}
